const FRAME_DEFAULT_BEDDING = 5;
const FRAME_MINIMAL_SIZE = 7;

const getSizehalved = (frameSize, monumentSize) => {
  if (!frameSize || !monumentSize || monumentSize > frameSize) {
    return 0
  } else {
    return ((frameSize - monumentSize) / 2)
  }
}

export const frameDimensions = (frame, monumentLayoutSize) => {
  const { layout, width, depth } = frame;
  //Cas particulier de la recette W en standard non centré donc les tailles ne sont pas bonnes ne pas les afficher
  if (frame.mode !== "custom" && frame.reference && frame.reference[0] === "W") { return null }
  let dimensions = { front: {}, left: {}, back: {}, right: {} };
  const bedding = getBedding(frame, monumentLayoutSize);
  const frontDepth = getFrontDepth(frame, monumentLayoutSize);
  ["back", "left", "right", "front"].forEach(side => dimensions[side] = { width: 0, depth: 0 });
  dimensions.front.width = width; // Largeur avant = largeur semelle car toujours parpaing avant
  dimensions.front.depth = frontDepth + bedding; // Profondeur avant = Vue avant + lit de pose
  dimensions.back.width = layout === "parpaing" ? width : monumentLayoutSize.width - 2 * bedding; // Largeur arrière = semelle si parpaing sinon monument - 2*lit de pose 
  dimensions.back.depth = depth - monumentLayoutSize.depth - frontDepth + bedding; // Profondeur arrière = semelle - monument - vue avant + lit de pose  
  dimensions.left.width = getSizehalved(width, monumentLayoutSize.width) + bedding; // C'est la moitié de la différence semelle/monument + le lit de pose
  dimensions.left.depth = layout === "parpaing" ? depth - dimensions.front.depth - dimensions.back.depth : depth - dimensions.front.depth;
  // Pour profondeur gauche et droite si parpaing on enlève les profondeurs avant et arrière sinon seulement l'avant
  dimensions.right = { ...dimensions.left };

  return dimensions;
}

export const getFrontDepth = (frame, monumentLayoutSize) => {
  const { depth, initDepth, frontDepth } = frame;

  if (!initDepth) {
    return getSizehalved(depth, monumentLayoutSize.depth);
  }
  return frontDepth;
}

const getBedding = (frame, monumentLayoutSize) => {
  const { width, depth, initDepth, frontDepth, bedding } = frame;
  if (!bedding || bedding < FRAME_MINIMAL_SIZE) { // On ne modifie que si non renseigné ou infériur à la taille minimale
    if (initDepth && (frontDepth === 0 || ((depth - monumentLayoutSize.depth - frontDepth) === 0))) {
      return FRAME_MINIMAL_SIZE  // Si vue avant (ou arrière) mise à zéro on retourne la taille minimale
    }
    if ((monumentLayoutSize.depth === depth) || (monumentLayoutSize.width === width)) {
      return FRAME_MINIMAL_SIZE   // Si la largeur ou la profondeur de la recette est égale à celle de la semelle on retourne la taille minimale
    }
  }
  if (!bedding) {
    return FRAME_DEFAULT_BEDDING
  }

  return bedding
}
