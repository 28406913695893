import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import * as _ from 'lodash';
import {OptionPanel} from '../components/OptionPanel';
import {InputNumber} from '../../../components/InputNumber';
import {formatFrame} from '../../../services/format.service';
import {isMonumentCineraire, replaceMultiplicationSymbol} from '../../../services/utils.service';
import cn from 'classnames';
import {Table} from 'reactstrap';
import { isConfigRequested } from "../../../services/configurationWarningChecks"
import { TranslationContext } from "../../../context/TranslationContext";
import { Modal } from 'reactstrap'
import { isSmartphone } from '../../../services/domFunctions';
import { getFrontDepth } from '../../../services/frame.service';

export const FrameOptionPanelId = "frameOptionPanel";

const simpleHeightMinMax = [3, 8];
const doubleHeightMinMax = [5, 10];
const MIN_BEDDING = 5;
const MAX_BEDDING = 20;
const frSides={front:"Avant",back:"Arrière",left:"Gauche",right:"Droit"}

// Minimum de vue avant / arrière
const minFrameWidth = 1;

class FrameOptionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.onChangeBedding = _.debounce(this.onChangeBedding.bind(this), 1000);
  }
  
  static contextType = TranslationContext;
  static propTypes = {
    frame: PropTypes.shape({
      mode: PropTypes.string.isRequired,
      reference: PropTypes.string,
      kind: PropTypes.string,
      width: PropTypes.number,
      depth: PropTypes.number,
      heights: PropTypes.arrayOf(PropTypes.number),
      frontDepth: PropTypes.number,
      layout: PropTypes.string,
    }),
    frames: PropTypes.arrayOf(PropTypes.shape({
      kind: PropTypes.string.isRequired,
      defaults: PropTypes.arrayOf(PropTypes.shape({
        kind: PropTypes.string.isRequired,
        reference: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        depth: PropTypes.number.isRequired,
        heights: PropTypes.arrayOf(PropTypes.number).isRequired,
        frontDepth: PropTypes.number.isRequired,
        layout: PropTypes.string.isRequired,
      })).isRequired,
      heights: PropTypes.number.isRequired
    })).isRequired
  };
  state={showDim:false, modalIsOpen:false}

  onSelectKind = (event) => {
    const kind = event.target.value,
      frame = this.props.frame;
    // Si aucun type n'est sélectionné, on supprime toute la semelle
    if (!kind) {
      return this.removeFrame();
    }

    const kindGroup = _.find(this.props.frames, {kind}),
      firstDefault = kindGroup.defaults && kindGroup.defaults[0],
      defaultHeight = (firstDefault && firstDefault.heights) || [7, 5].slice(-kindGroup.heights);

    if (!kindGroup.defaults || !kindGroup.defaults.length) {
      let width = 140;
      let depth = 240;
      if (isMonumentCineraire(this.props.monument)) {
        width = 90;
        depth = 90;
      }
      // Si pas de semelles pré-définies, on force le custom
      return this.updateFrame({
        kind,
        mode: 'custom',
        layout:"parpaing", //On met parpaing arrière par défaut en custom
        heights: defaultHeight,
        width: frame.width || width,
        depth: frame.depth || depth,
      });
    } else if (frame.mode === 'custom') {
      // Si l'on était en mode custom, on modifie le kind et les heights
      return this.updateFrame({
        kind,
        heights: defaultHeight,
        layout:"parpaing", //On met parpaing arrière par défaut en custom
      });
    } else {
      const nearestFrame = this.getExactFrameBySize(frame, kindGroup.defaults, kindGroup.kind, defaultHeight);

      if(nearestFrame.mode === 'custom') {
        return this.updateFrame(nearestFrame);
      } else {
        return this.updateFrame({
          ..._.omit(nearestFrame, ['name']),
          mode: 'standard',
          kind,
        });
      }

    }

  };

  toggleMode = () => {
    const frame = this.props.frame;

    if (frame.mode === 'standard') {
      // Passage en mode custom
      return this.updateFrame({
        mode: 'custom',
        layout:"parpaing", //On met parpaing arrière par défaut en custom
      })
    }

    if (frame.mode === 'custom') {
      // Passage en mode standard, on écrase tout sauf le kind
      return this.setToStandard(frame.kind, null)
    }
  };

  setToStandard = (kind, reference) => {
    const kindGroup = _.find(this.props.frames, {kind});

    const defaultParams = _.find(kindGroup.defaults, {reference}) || this.getNearestFrameBySize(
      this.props.frame,
      kindGroup.defaults,
      {
        mode: 'custom',
      }
    );

    return this.updateFrame({
      mode: 'standard',
      ..._.omit(defaultParams, ['name']),
    });
  };

  /**
   * Recherche parmi les semelles par défaut, la semelle qui a les dimensions les plus proches de la semelle de référence
   * @param referenceFrame
   * @param frameGroup
   * @param fallbackFrame
   * @returns {*}
   */
  getNearestFrameBySize = (referenceFrame, frameGroup, fallbackFrame) => {
    // On teste sur la largeur et la profondeur
    const valuesToTest = ['width', 'depth'];

    return  (_.chain(frameGroup)
      .map((params) => ({
        params,
        distance: _.chain(valuesToTest)
          .map(property => Math.abs(_.get(params, property) - _.get(referenceFrame, property)))
          .sum()
          .value()
      }))
      .orderBy('distance')
      .head()
      .value() || { params: fallbackFrame })
      .params;
  };

  /**
   * Recherche parmi les semelles par défaut la semelle qui a les mêmes dimensions que la semelle de référence ou retourne une semelle custom
   * @param referenceFrame
   * @param frameGroup
   * @param targetKind
   * @param defaultHeight
   * @returns {*|{kind: *, mode: string, heights: *, width: (*|number), depth: (*|number)}}
   */
  getExactFrameBySize = (referenceFrame, frameGroup, targetKind, defaultHeight) => {
    const sameSizedFrame = frameGroup.find(frame => frame.width === referenceFrame.width && frame.depth === referenceFrame.depth);
    return sameSizedFrame || {
      kind: targetKind,
      mode: 'custom',
      heights: defaultHeight,
      width: referenceFrame.width || 140,
      depth: referenceFrame.depth || 240,
    };
  };

  onSelectStandardReference = (event) => {
    this.setToStandard(this.props.frame.kind, event.target.value);
  };

  onChangeWidth = (width) => this.updateFrame({width});
  onChangeDepth = (depth) => this.updateFrame({depth});
  onChangeBedding = (bedding) => {
    if (bedding >= MIN_BEDDING && bedding <= MAX_BEDDING) {
      return this.updateFrame({bedding});
    }
  };
  onChangeFrontDepth = frontDepth => this.updateFrame({frontDepth: frontDepth ? frontDepth : 0});

  onChangeLayout = (event) => this.updateFrame({layout: event.currentTarget.value});

  onChangeHeight = (height) => {
    const firstHeight = Math.min(this.props.frame.heights.length > 1 ? doubleHeightMinMax[1] : simpleHeightMinMax[1],
      Math.max(this.props.frame.heights.length > 1 ? doubleHeightMinMax[0] : simpleHeightMinMax[0], height || 0));
    const secondHeight = this.props.frame.heights.length > 1 ? [firstHeight - 2] : [];
    this.updateFrame({heights: [firstHeight, ...secondHeight]})
  };

  /**
   * Retourne la référence d'une frame custom.
   * @param configuration
   * @returns {string}
   */

  getCustomFrameReference = () => {
    return 'SEM';
  };

  updateFrame = (newValue) => {
    const newFrame = Object.assign({}, this.props.frame, newValue);
    if (newFrame.mode === 'custom') {
      const layout = _.find(this.props.layouts, {reference: this.props.monument.layout});
      newFrame.reference = this.getCustomFrameReference();

      // Check if first time change by user
      if (newValue.hasOwnProperty('frontDepth') && !isNaN(newValue.frontDepth)) {
        newFrame.initDepth = true;
      }
      newFrame.frontDepth = getFrontDepth(newFrame, layout.size);
    } else {
      newFrame.initDepth = false;
    }

    this.props.actions.updateFrame(newFrame);
  };

  removeFrame = () => this.props.actions.removeFrame();
  defaultFrontDepth = () => (this.props.frame.depth - this.getMonumentLayoutDepth()  ) / 2;
  getMonumentLayoutDepth = () => _.find(this.props.layouts, {reference: this.props.monument.layout}).size.depth;

  openModal = () => {
    this.setState({modalIsOpen:true})
  }

  render() {
    const t = this.context;
    const monumentComponents = ["SEM","PLT","ACC"];
    const isMonumentComponent = monumentComponents.includes(this.props.monument.category)   
    const frame = this.props.frame,
      mode = frame.mode,
      kind = mode && frame.kind,
      frames = this.props.frames,
      kindGroup = _.find(frames, {kind}),
      layout = _.find(this.props.layouts, {reference: this.props.monument.layout}),
      frontDepth = getFrontDepth(frame, layout.size);
      const sizes = frame.details ? frame.details.replace(/\s/g, '') : "";
      const displayValue = replaceMultiplicationSymbol(frame.depth && frame.depth !==0 ? sizes:"NON");
    const disabledFrame=this.props.frameEnabled !== undefined && !this.props.frameEnabled


      const disabled = false || isConfigRequested(false);
      const buttonContent = t("config3d_stepper_frame") || "Semelle";

      const FrameOptionContent = () =>  
        <div>
          { isSmartphone() && (
            <div className='header modal-header'>
              <h2 className='modal-title'>{this.props.title}</h2>
              <button
                type='button'
                className='close'
                aria-label='Close'
                onClick={() => this.setState({modalIsOpen:!this.state.modalIsOpen})}
              >
                <i className='icon material-icons'></i>
              </button>
            </div>

          )}
          <label className="title">
            { t("config3d_monument_modal_frame_type") || "Type de semelle" }
          </label>
          <div>
            <select value={kind} onChange={this.onSelectKind}>
              {this.props.monument.category !== "SEM" && <option value="">
                { t("config3d_monument_modal_frame_list_noframe") || "Sans semelle" }
              </option>
                }
              {frames.map(frame => (
                <option key={frame.kind}
                        value={frame.kind}>Semelle {frame.label}</option>
              ))}
            </select>
          </div>
          {mode && (
            <div>
              <div>
                <label className="checkbox">
                  <input type="checkbox" className="custom-checkbox" id="checkbox"
                    checked={mode === 'custom'}
                    disabled={!kindGroup.defaults || !kindGroup.defaults.length}
                    onChange={this.toggleMode}
                  />
                  <label for="checkbox">
                    <span>
                      { t("config3d_monument_modal_frame_checkbox_customsize") || "Dimensions sur-mesure" }
                    </span>
                  </label>
                </label>
              </div>

              {mode === 'standard' && (
                <>
                <label className="title">Dimensions</label>
                <div>
                  <select value={frame.reference} onChange={this.onSelectStandardReference}>
                    {kindGroup.defaults.map(std => (
                      <option key={std.reference} value={std.reference}>{formatFrame(std)}</option>
                    ))}
                  </select>
                </div>
                </>
              )}
              {mode === 'custom' && (
                <div>
                  <div style={{ display:"flex", gap:"16px" }}>
                    <div className="CustomProperty">
                      <label className="Label">
                        { t("config3d_monument_modal_frame_custom_front") || "Façade" }
                      </label>
                      <div className="input-wrapper">
                        <InputNumber 
                          className="custom-input-field" 
                          value={frame.width} 
                          min={layout.size.width + minFrameWidth * 2} 
                          max="460"
                          onValueChange={this.onChangeWidth}/>
                        <span className="custom-input-addon">
                          { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                        </span>
                      </div>
                    </div>
                    <div className="CustomProperty">
                      <label className="Label">
                        { t("config3d_monument_modal_frame_custom_depth") || "Profondeur" }
                      </label>
                      <div className="input-wrapper">
                        <InputNumber 
                          className="custom-input-field" 
                          value={frame.depth} 
                          min={layout.size.depth + minFrameWidth * 2} 
                          max="460"
                          onValueChange={this.onChangeDepth}/>
                        <span className="custom-input-addon">
                          { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                        </span>
                      </div>
                    </div>
                    <div className="CustomProperty Heights">
                      <label className="Label">
                        { t("config3d_monument_modal_frame_custom_height") || "Hauteur" }
                      </label>
                      <div className="input-wrapper">
                        {frame.heights.length === 1 ? (
                          <InputNumber 
                            className="custom-input-field" 
                            value={frame.heights[0]} 
                            min={simpleHeightMinMax[0]} 
                            max={simpleHeightMinMax[1]} 
                            step={1} 
                            onValueChange={this.onChangeHeight}/>
                        ) : [(
                          <InputNumber 
                            className="custom-input-field" 
                            key="height-0" 
                            value={frame.heights[0]} 
                            min={doubleHeightMinMax[0]} 
                            max={doubleHeightMinMax[1]} 
                            step={1} 
                            onValueChange={this.onChangeHeight}/>
                        ), (
                            <>
                              <span className="Unit">/</span>
                              <InputNumber 
                                className="custom-input-field disabled" 
                                value={frame.heights[1]} 
                                disabled="disabled" />
                            </>
                        )]}
                        <span className="custom-input-addon">
                          { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                        </span>
                      </div>
                    </div>
                  </div>

                  <div style={{ display:"flex", gap:"16px" }}>
                    <div className="CustomProperty">
                      <label className="Label">
                      { t("config3d_monument_modal_frame_custom_frontoffset") || "Vue avant" }
                      </label>
                      <div className="input-wrapper">
                        <InputNumber 
                          className="custom-input-field" 
                          value={frontDepth} 
                          min={minFrameWidth} 
                          max={frame.depth - layout.size.depth - minFrameWidth}
                          onValueChange={value => this.onChangeFrontDepth(value)}/>
                        <span className="custom-input-addon">
                          { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                        </span>
                      </div>
                    </div>
                    <div className="CustomProperty">
                      <label className="Label">
                        { t("config3d_monument_modal_frame_custom_backoffset") || "Vue arrière" }
                      </label>
                      <div className="input-wrapper">
                        <InputNumber 
                          className="custom-input-field disabled" 
                          value={frame.depth - layout.size.depth - frontDepth} 
                          disabled={true} />
                        <span className="custom-input-addon">
                          { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                        </span>
                      </div>
                    </div>
                    <div className="CustomProperty">
                      <label className="Label">
                        { t("config3d_monument_modal_frame_custom_pavingbed") || "Lit de pose" }
                      </label>
                      <div className="input-wrapper">
                        <InputNumber 
                          className="custom-input-field" 
                          value={frame.bedding} 
                          min={MIN_BEDDING} 
                          max={MAX_BEDDING}
                          onFocus={event => event.target.select()}
                          onValueChange={value => this.onChangeBedding(value)}
                          />
                        <span className="custom-input-addon">
                          { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                        </span>
                      </div>
                    </div>
                  </div>

                  <label className="title">{ t("config3d_monument_modal_frame_footer") || "Parpaing / Closoire" }</label>
                  <div className="CustomProperty" style={{ display:"flex", gap:"1rem" }}>
                    <label className="radio">
                      <input type="radio" name="layout" className="radio-input" value="parpaing" id="radio1"
                        checked={frame.layout === 'parpaing'}
                        onChange={this.onChangeLayout} />
                      <label className="radio-label" for="radio1">
                        <span className="radio-border"></span>
                        { t("config3d_monument_modal_frame_custom_back_breezeblock") || "Parpaing arrière" }
                      </label>
                    </label>
                    <label className="radio">
                      <input type="radio" name="layout" className="radio-input" value="closoir" id="radio2"
                        checked={frame.layout === 'closoir'}
                        onChange={this.onChangeLayout}/>
                      <label className="radio-label" for="radio2">
                        <span className="radio-border"></span>
                        { t("config3d_monument_modal_frame_custom_back_closure") || "Closoir arrière" }
                      </label>
                    </label>
                  </div>    
                </div>
              )}

                {this.state.showDim && frame.dimensions && <div>
                  <Table striped bordered className="TableFramePieces">
                  <thead><tr><th>Pièce</th><th>Largeur</th><th>Profondeur</th><th>Hauteur</th></tr></thead>
                  <tbody>
                    {["front", "back", "left", "right"].map(side=>
                    <tr><td>{frSides[side]}</td><td>{frame.dimensions[side].width}</td><td>{frame.dimensions[side].depth}</td><td>{frame.heights[0]}</td></tr>
                    )}
                    </tbody>
                  </Table>
                </div>
              }  
            </div>
          )}
        </div>

      return (
        <OptionPanel
          id={FrameOptionPanelId}
          className={cn('FrameOptionPanel ' + mode, {mode})}
          buttonClassName={`btn-config-secondary text-dark config-button-background	 ${!disabledFrame ? 'config-button-hover' : ''}`}
          buttonContent={buttonContent}
          buttonIcon="frame-icon"
          title={disabledFrame && "Option non disponible pour ce monument"}
          hasContent={!!kind}
          showValue={displayValue}
          disabled={disabledFrame}
          isMonComponent={isMonumentComponent}
          isFrame={true}
          openModal={this.openModal}
        >

        {isSmartphone() ? 
          <Modal
            isOpen={this.state.modalIsOpen}
            className='ModalFrameOptionPanel'
            centered={true}
            fade={true}
          >
            <FrameOptionContent />
          </Modal> : 
          <FrameOptionContent />
        }
        </OptionPanel>
    )
  }
}

export const FrameOptionPanel = connect((state, ownProps) => ({
    monument: state.configurator.current.configuration.monument,
    frame: state.configurator.current.configuration.frame,
    frames: state.configurator.current.options.frames,
    layouts: state.configurator.current.options.layouts,
    frameEnabled:state.configurator.current.options.functionalities.frameEnabled
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(FrameOptionPanelComponent);

